import { useState, useContext } from 'react'
import { Link } from "react-router-dom";
import PropTypes from 'prop-types'
import { CartContext } from '../context/cart.jsx'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Button from "react-bootstrap/Button";

import Modal from "react-bootstrap/Modal";

export default function Cart ({showModal, toggle}) {

  const { cartItems, addToCart, removeFromCart, clearCart, getCartTotal } = useContext(CartContext)

  const notifyRemovedFromCart = (item) => toast.error(`${item.name} removed from cart!`, {
    position: 'bottom-center',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'colored',
    style: {
      backgroundColor: '#000',
      color: '#fff'
    }
  })

  const notifyCartCleared = () => toast.error(`Cart cleared!`, {
    position: 'bottom-center',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'colored',
    style: {
      backgroundColor: '#000',
      color: '#fff'
    }
  })

  const handleRemoveFromCart = (product) => {
    removeFromCart(product)
    notifyRemovedFromCart(product)
  }


// console.log(cartItems);  

  return (
    showModal && (
      <>
        <ToastContainer />

        <Modal
        fullscreen={true}
                  animation={false}
                  show={showModal}
                   onHide={toggle}
                  size="xl"
                   backdrop="static"
        keyboard={false}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header className="mx-2 justify-content-between shadow-sm" closeButton>
                    <Modal.Title>
                      <h6 className="text-site soko">
                        <span className="badge badge-warning bg-warning">{cartItems.length}</span> Cart - 
          {
          cartItems.length > 0 ? (
          
          <span className="soko-bold text-center"> KSh {getCartTotal()}</span>
 
          ) : (

          <span> Ksh 0</span>
          )
        }
                      </h6>
                   
                    </Modal.Title>
                       <div>


         
                      </div>
                  </Modal.Header>
                  <Modal.Body>
                    

<div className="table-responsive">
<div className="text-center">
                  {
          cartItems.length > 0 ? (
          
          <span className="soko-bold">Total: KSh. {getCartTotal()}</span>
 
          ) : (

          <span>Ksh. 0</span>
          )
        }
</div>
         <table className="table row-border stripe">
 <thead>
                  <tr>
         <th>Image</th>
         <th>Product</th>
         <th>Price</th>
         <th className="text-center">Qnty</th>
          </tr>
               </thead>
               <tbody>

           {cartItems.map((item) => (
          

            <tr className="mb-3" key={item.id}>


         <td><Link to={`/product/${item.slug}`}><img src={
                            item.image
                              ? `https://myduka.codekali.com/product/image/${item.image}`
                              : `https://myduka.codekali.com/app/images/avatar.png`
                          } alt={item.name} className="product-thumbnail" /></Link></td>
         <td><Link to={`/product/${item.slug}`}><small className="soko-bold">{item.name}</small></Link></td>
         <td><small className="text-dark">KSh{item.price}</small></td>
         <td className="text-center">
                    <div className="d-flex align-items-center gap-2">

                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    const cartItem = cartItems.find((product) => product.id === item.id);
                    if (cartItem.quantity === 1) {
                      handleRemoveFromCart(item);
                    } else {
                      removeFromCart(item);
                    }
                  }}
                >
                  -
                </button>
                <span>{item.quantity}</span>
                  <button
                  className="btn btn-primary"
                  onClick={() => {
                    addToCart(item)
                  }}
                >
                  +
                </button>
              </div>

         </td>



            </tr>
          ))}
               </tbody>

         </table>
 </div>
{
          cartItems.length > 0 ? (
            <div className="flex flex-col justify-between items-center">
          <h3 className="soko-bold">Total: KSh. {getCartTotal()}</h3>
          <button
            className="btn btn-secondary"
            onClick={() => {
              clearCart()
              notifyCartCleared()
            }}
          >
            Clear cart
          </button>
        </div>
          ) : (
            <h6 className="text-center">Your cart is empty. <Link className="soko-bold" to={`/shop`}>Shop now!</Link></h6>
          )
        }


                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="warning"
                      className="soko-round"
                       onClick={toggle}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>

</>
    )
  )
}

Cart.propTypes = {
  showModal: PropTypes.bool,
  toggle: PropTypes.func
}

