import React, { useContext } from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Carousel from "react-bootstrap/Carousel";
import "react-multi-carousel/lib/styles.css";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import homeimg from "../images/home.png";
import homeimg2 from "../images/home2.png";
import homeimg3 from "../images/home3.jpg";
import homeimg4 from "../images/home4.jpg";
import placeholder from "../images/lazyloader.png";
import homeintro from "../images/homeintro.gif";
import homeintromobile from "../images/homeintromobile.gif";


import { faCartPlus } from "@fortawesome/free-solid-svg-icons";

import Cart from './Cart'
import { CartContext } from '../context/cart.jsx'

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';


const Home = (props) => {
  const nextIcon = <FontAwesomeIcon className="fs-3" icon={faAngleRight} />;
  const prevIcon = <FontAwesomeIcon className="fs-3" icon={faAngleLeft} />;

  const [homecats, setHomeCats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    return (
      fetch("https://myduka.codekali.com/api/categories")
        // return fetch("https://myduka.codekali.com/api/categories")
        .then((response) => response.json())
        .then((data) => setHomeCats(data["categories"]))
        .finally(() => {
          setIsLoading(false);
        })
    );
  };

  // console.log(homecats);

  const [products, setProducts] = useState([]);
  const [isLoadingPrd, setIsLoadingPrd] = useState(true);
  const fetchProducts = () => {
    return (
      fetch("https://myduka.codekali.com/api/getproducts")
        // return fetch("https://myduka.codekali.com/api/getproducts")
        .then((response) => response.json())
        .then((data) => setProducts(data["products"]))
        .finally(() => {
          setIsLoadingPrd(false);
        })
    );
  };

  useEffect(() => {
    setIsLoading(true);
    setIsLoadingPrd(true);
    fetchProducts();
    fetchData();
    // setIsLoading(false);
  }, []);

  function capFirst(str) {
    let dots;

    if (str.length > 50) {
      dots = "...";
    } else {
      dots = "";
    }

    return (
      (str[0].toUpperCase() + str.slice(1).toLowerCase()).slice(0, 50) + dots
    );
  }

   const formatPrice = (value) =>
  new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'KES'
  }).format(value);



// cart

   const { cartItems, addToCart , removeFromCart} = useContext(CartContext)

   


  const notifyAddedToCart = (item) => toast.success(`${item.name} added to cart!`, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'colored',
    style: {
      backgroundColor: 'green',
      color: '#fff',
    }
    });

  const notifyRemovedFromCart = (item) => toast.error(`${item.name} removed from cart!`, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'colored',
    style: {
      backgroundColor: 'red',
      color: '#fff',
    }
    });

    const handleRemoveFromCart = (product) => {
      removeFromCart(product);
      notifyRemovedFromCart(product);
    };

  return (
    <>
      <div className="mb-1">
        <img
          loading="lazy"
          alt="sokomoko"
          className="img-fluid mobile-top-ad w-100"
          src={homeintromobile}
        />
        <img
          loading="lazy"
          alt="sokomoko"
          className="img-fluid desktop-top-ad w-100"
          src={homeintro}
        />
      </div>
      <Header />
        <ToastContainer />
      <div className="container-fluid">
        <div className="row mb-3">
          <div id="soko_cat_menu" className="col-md-3">
            <aside id="soko-left-menu" className="shadow-sm">
              <ul className="soko-cats-menu">
                {!isLoading
                  ? homecats &&
                    homecats.length > 0 &&
                    homecats.map((homecatsObj) => (
                      <li key={homecatsObj.id}>
                        <Link to={`/category/${homecatsObj.slug}`}>
                          <img
                            loading="lazy"
                            className="img-fluid nav-cat-img me-2"
                            src={
                              homecatsObj.image
                                ? `https://myduka.codekali.com/category/image/${homecatsObj.image}`
                                : `https://myduka.codekali.com/app/images/avatar.png`
                            }
                            alt="{homecatsObj.name}"
                          />
                          {homecatsObj.name}{" "}
                          <FontAwesomeIcon
                            className="float-end me-3 nav-cat-icon"
                            icon={faAngleRight}
                          />
                        </Link>
                        {homecatsObj.subcategories.length > 0 ? (
                          <ul className="shadow-sm">
                            {homecatsObj.subcategories.slice(0, 12) &&
                              homecatsObj.subcategories.slice(0, 12).length >
                                0 &&
                              homecatsObj.subcategories
                                .slice(0, 12)
                                .map((csubcatsObj) => (
                                  <li key={csubcatsObj.id}>
                                    <Link
                                      to={`/subcategory/${csubcatsObj.slug}`}
                                    >
                                      <ProgressiveImage
                                        src={
                                          csubcatsObj.image
                                            ? `https://myduka.codekali.com/subcategory/image/${csubcatsObj.image}`
                                            : `https://myduka.codekali.com/app/images/avatar.png`
                                        }
                                        placeholder={
                                          csubcatsObj.image
                                            ? `https://myduka.codekali.com/subcategory/image/${csubcatsObj.image}`
                                            : `https://myduka.codekali.com/app/images/avatar.png`
                                        }
                                      >
                                        {(src, loading) => (
                                          <img
                                            className={`image${
                                              loading
                                                ? " loading img-fluid nav-cat-img me-2"
                                                : " loaded img-fluid nav-cat-img me-2"
                                            }`}
                                            src={src}
                                            alt="{csubcatsObj.name}"
                                          />
                                        )}
                                      </ProgressiveImage>

                                      {csubcatsObj.name}
                                    </Link>
                                  </li>
                                ))}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    ))
                  : Array(7)
                      .fill()
                      .map((item, index) => (
                        <li className="mb-3" key={index}>
                          <div className="d-flex justify-content-between">
                            <div className="col-md-3">
                              <Skeleton
                                rectangle={true}
                                height={30}
                                width={60}
                              />
                            </div>

                            <div className="col-md-9">
                              <Skeleton height={30} width={`100%`} />
                            </div>
                          </div>
                        </li>
                      ))}
              </ul>
            </aside>
          </div>
          <div className="col-md-6 mb-3">
            <Carousel nextIcon={nextIcon} prevIcon={prevIcon} fade>
              <Carousel.Item>
                {/*<img loading="lazy"
                           className="d-block w-100"
                           src={homeimg}
                           alt="First slide"
                           />*/}

                <ProgressiveImage src={homeimg} placeholder={placeholder}>
                  {(src, loading) => (
                    <img
                      className={`image${
                        loading
                          ? " loading d-block w-100"
                          : " loaded d-block w-100"
                      }`}
                      src={src}
                      alt="home1"
                    />
                  )}
                </ProgressiveImage>
              </Carousel.Item>
              <Carousel.Item>
                {/* <img loading="lazy"
                           className="d-block w-100"
                           src={homeimg4}
                           alt="Second slide"
                           />*/}

                <ProgressiveImage src={homeimg4} placeholder={placeholder}>
                  {(src, loading) => (
                    <img
                      className={`image${
                        loading
                          ? " loading d-block w-100"
                          : " loaded d-block w-100"
                      }`}
                      src={src}
                      alt="home4"
                    />
                  )}
                </ProgressiveImage>
              </Carousel.Item>
              <Carousel.Item>
                {/* <img loading="lazy"
                           className="d-block w-100"
                           src={homeimg2}
                           alt="Second slide"
                           />*/}
                <ProgressiveImage src={homeimg2} placeholder={placeholder}>
                  {(src, loading) => (
                    <img
                      className={`image${
                        loading
                          ? " loading d-block w-100"
                          : " loaded d-block w-100"
                      }`}
                      src={src}
                      alt="home2"
                    />
                  )}
                </ProgressiveImage>
              </Carousel.Item>
              <Carousel.Item>
                {/*   <img loading="lazy"
                           className="d-block w-100"
                           src={homeimg3}
                           alt="Third slide"
                           />*/}

                <ProgressiveImage src={homeimg3} placeholder={placeholder}>
                  {(src, loading) => (
                    <img
                      className={`image${
                        loading
                          ? " loading d-block w-100"
                          : " loaded d-block w-100"
                      }`}
                      src={src}
                      alt="home3"
                    />
                  )}
                </ProgressiveImage>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="col-md-3 mobile-hide">
            <div className="card soko-rounded mb-3">
              <div className="card-body">
                <div className="d-flex justify-content-between mb-4">
                  <div className="text-start">
                    <FontAwesomeIcon
                      className="fs-2 text-warning"
                      icon={faQuestionCircle}
                    />
                  </div>
                  <div className="text-end">
                    <h6 className="soko-bold">HELP CENTER</h6>

                    <p>
                      <small>Call/Whatsapp us 24/7</small>
                    </p>
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between mb-4">
                  <div className="text-start">
                    <FontAwesomeIcon
                      className="fs-2 text-warning"
                      icon={faTruck}
                    />
                  </div>
                  <div className="text-end">
                    <h6 className="soko-bold">FAST DELIVERY</h6>
                    <p>
                      <small>Delivery within 24-48 hours</small>
                    </p>
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between mb-4">
                  <div className="text-start">
                    <FontAwesomeIcon
                      className="fs-2 text-warning"
                      icon={faCartShopping}
                    />
                  </div>
                  <div className="text-end">
                    <h6 className="soko-bold">QUALITY</h6>
                    <p>
                      <small>Our products are certified</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {!isLoading
            ? homecats &&
              homecats.length > 0 &&
              homecats.map((homecatsObj) => (
                <div key={homecatsObj.id} className="col-md-2 col-6 mb-3">
                  <Link
                    className="text-site"
                    to={`/category/${homecatsObj.slug}`}
                  >
                    <div className="card soko-cat-card shadow-sm h-100">
                      <div className="card-img-top">
                        <img
                          loading="lazy"
                          className="img-fluid soko-cat-img"
                          src={
                            homecatsObj.image
                              ? `https://myduka.codekali.com/category/image/${homecatsObj.image}`
                              : `https://myduka.codekali.com/app/images/avatar.png`
                          }
                          alt="{homecatsObj.name}"
                        />
                      </div>
                      <div className="card-img-overlay text-white d-flex justify-content-center align-items-center">
                        <h6 className="text-white soko-bold">
                          {homecatsObj.name}
                        </h6>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            : Array(6)
                .fill()
                .map((item, index) => (
                  <div className="col-md-2 col-6 mb-3" key={index}>
                    <div className="card shadow-sm">
                      <div className="card-body">
                        <div style={{ height: "80px" }}>
                          <Skeleton height={80} width={`100%`} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          <Skeleton width={`60%`} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
        </div>

        <div className="row g-1">
        <div className="card">
        <div className="card-body">
              <div className="cleafix">
          <div className="float-start">
          <h5 className="latest-products soko-bold">Best Sellers</h5>
          </div>
          <div className="float-end">
          <Link className="text-dark" to={`/shop`}>See All  <FontAwesomeIcon icon={faAngleRight}
                    /></Link>
          </div>
          </div>
        </div>
        </div>
          {!isLoadingPrd
            ? products &&
              products.length > 0 &&
              products.map((productsObj) => (
                <div key={productsObj.id} className="col-md-3 col-6 mb-3">
                    <div className="card shadow-sm h-100">
                      <div className="card-body">
                  <Link to={`/product/${productsObj.slug}`}>
                        <img
                          loading="lazy"
                          className="img-fluid mb-2 product-img w-100"
                          src={
                            productsObj.image
                              ? `https://myduka.codekali.com/product/image/${productsObj.image}`
                              : `https://myduka.codekali.com/app/images/avatar.png`
                          }
                          alt="{productsObj.name}"
                        />

                        <h6 className="soko-mobile-font mb-2">
                          {capFirst(productsObj.name)}
                        </h6>
                        <h6 className="soko-bold soko-mobile-font">
                          {formatPrice(productsObj.price)}
                        </h6>
                  </Link>
                        
                          {
                  !cartItems.find(item => item.id === productsObj.id) ? (
                    <button className="btn btn-primary soko-rounded w-100"
                      onClick={() => {
                        addToCart(productsObj)
                        notifyAddedToCart(productsObj)
                      }
                      }
                      >
                        <FontAwesomeIcon className="me-1" icon={faCartPlus} /> Add to cart
                      </button>
                  ) : (
                    <div className="d-flex align-items-center gap-4">
                        <button
                      className="btn btn-secondary soko-round w-50"
                      onClick={() => {
                        const cartItem = cartItems.find((item) => item.id === productsObj.id);
                        if (cartItem.quantity === 1) {
                          handleRemoveFromCart(productsObj);
                        } else {
                          removeFromCart(productsObj);
                        }
                      }}
                    >
                      -
                    </button>
                    <small>{cartItems.find(item => item.id === productsObj.id).quantity}</small>
                    <button
                      className="btn btn-primary soko-round w-50"
                      onClick={() => {
                        addToCart(productsObj)
                      }}
                    >
                      +
                    </button>
                
                  </div>
                  )
                }
                      </div>
                    </div>
                </div>
              ))
            : Array(50)
                .fill()
                .map((item, index) => (
                  <div className="col-md-3 col-6 mb-3" key={index}>
                    <div className="card shadow-sm">
                      <div className="card-body">
                        <div>
                          <Skeleton className="product-img" width={`100%`} />
                        </div>
                        <h6 className="card-title">
                          <Skeleton height={25} width={`80%`} />
                        </h6>
                        <p className="card-channel mb-0">
                          <Skeleton width={`60%`} />
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
