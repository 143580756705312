import React from 'react';
import { useState } from "react";
import { Link } from "react-router-dom";
import Header from './layouts/Header';
import Footer from './layouts/Footer';


 function SearchProduct() {
	
	const [searchproducts, setData] = useState([])
	
	 async function search(key){

		console.warn(key)

		let result = await fetch("https://myduka.codekali.com/api/search/"+key)

		result = await result.json();

		setData(result);

		  console.log(searchproducts)

	}

	function capFirst(str) {

   let dots

   if(str.length > 50){
      dots = '...';
   }else{
      dots = '';

   }

     return (str[0].toUpperCase() + (str.slice(1)).toLowerCase()).slice(0, 50)+dots;
     
 }

	return(
	<>
    <Header/>
	<div className="container-fluid">
		<div className="py-3"></div>
			<div className="card soko-rounded shadow-sm mb-3">
				<div className="card-body">
				<input onChange={(e)=>search(e.target.value)} className="w-100 form-control soko-round" type="search" placeholder="Search 1,000,000 products"/>
				<div className="row">
					<div className="py-3"></div>
				
					{
					searchproducts.map((productsObj)=>
					<div key={productsObj.slug} className="col-md-3 mb-3">
					<Link to={`/product/${productsObj.slug}`}>
					<div className="card soko-rounded shadow-sm h-100">
					<div className="card-body">
					<img loading="lazy" className="img-fluid mb-3 product-img" src={productsObj.image ? `https://myduka.codekali.com/product/image/${productsObj.image}` : `https://myduka.codekali.com/images/avatar.png`} 
					alt="{productsObj.name}" />
                       <h6 className="soko-bold">{capFirst(productsObj.name)}</h6>
					<p><strong> KES {productsObj.price}</strong></p>
 


					</div>
					
					</div>
					</Link>
					</div>
					)
					}
					
				</div>
			</div>
		</div>
	</div>
      <Footer/>
	</>
	)
}

export default SearchProduct