import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCartPlus } from "@fortawesome/free-solid-svg-icons";

import Cart from './Cart'
import { CartContext } from '../context/cart.jsx'

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';


const Shop = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchData = () => {
    return (
      fetch("https://myduka.codekali.com/api/getproducts")
        // return fetch("https://myduka.codekali.com/api/getproducts")
        .then((response) => response.json())
        .then((data) => setProducts(data["products"]))
        .finally(() => {
          setIsLoading(false);
        })
    );
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    // setIsLoading(false);
  }, []);

  function capFirst(str) {
    let dots;

    if (str.length > 50) {
      dots = "...";
    } else {
      dots = "";
    }

    return (
      (str[0].toUpperCase() + str.slice(1).toLowerCase()).slice(0, 50) + dots
    );
  }



// cart

   const { cartItems, addToCart , removeFromCart} = useContext(CartContext)

   


  const notifyAddedToCart = (item) => toast.success(`${item.name} added to cart!`, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'colored',
    style: {
      backgroundColor: 'green',
      color: '#fff',
    }
    });

  const notifyRemovedFromCart = (item) => toast.error(`${item.name} removed from cart!`, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'colored',
    style: {
      backgroundColor: 'red',
      color: '#fff',
    }
    });

    const handleRemoveFromCart = (product) => {
      removeFromCart(product);
      notifyRemovedFromCart(product);
    };

  return (
    <>
      <Header />
        <ToastContainer />
      <div className="container-fluid">
        <div className="py-3"></div>
        <div className="product-container">
          <div className="row">
            {!isLoading
              ? products &&
                products.length > 0 &&
                products.map((productsObj) => (
                  <div key={productsObj.id} className="col-md-3 mb-3">
                    <div className="card soko-rounded shadow-sm h-100">
                      <div className="card-body">
                        <Link to={`/product/${productsObj.slug}`}>
                          <img
                            loading="lazy"
                            className="img-fluid mb-2 product-img w-100"
                            src={
                              productsObj.image
                                ? `https://myduka.codekali.com/product/image/${productsObj.image}`
                                : `https://myduka.codekali.com/images/avatar.png`
                            }
                            alt="{productsObj.name}"
                          />
                          <div className="mb-2">
                            <small>
                              <span className="badge badge-primary bg-primary soko-rounded">
                                {productsObj.category.name}
                              </span>
                            </small>
                          </div>
                          <h6 className="mb-2">{capFirst(productsObj.name)}</h6>
                        </Link>
                        <h6 className="soko-bold">Ksh.{productsObj.price}</h6>
              {
                  !cartItems.find(item => item.id === productsObj.id) ? (
                    <button className="btn btn-primary soko-round w-100"
                      onClick={() => {
                        addToCart(productsObj)
                        notifyAddedToCart(productsObj)
                      }
                      }
                      >
                        <FontAwesomeIcon className="me-1" icon={faCartPlus} /> Add to cart
                      </button>
                  ) : (
                    <div className="d-flex align-items-center gap-4">
                        <button
                      className="btn btn-secondary soko-round w-50"
                      onClick={() => {
                        const cartItem = cartItems.find((item) => item.id === productsObj.id);
                        if (cartItem.quantity === 1) {
                          handleRemoveFromCart(productsObj);
                        } else {
                          removeFromCart(productsObj);
                        }
                      }}
                    >
                      -
                    </button>
                    <small>{cartItems.find(item => item.id === productsObj.id).quantity}</small>
                    <button
                      className="btn btn-primary soko-round w-50"
                      onClick={() => {
                        addToCart(productsObj)
                      }}
                    >
                      +
                    </button>
                
                  </div>
                  )
                }
                      </div>
                    </div>
                  </div>
                ))
              : Array(50)
                  .fill()
                  .map((item, index) => (
                    <div className="col-md-3 mb-3" key={index}>
                      <div className="card soko-rounded shadow-sm">
                        <div className="card-body">
                          <div
                            style={{ height: "200px", marginBottom: ".5rem" }}
                          >
                            <Skeleton height={200} width={`100%`} />
                          </div>
                          <h6 className="card-title">
                            <Skeleton height={25} width={`80%`} />
                          </h6>
                          <p className="card-channel mb-0">
                            <Skeleton width={`60%`} />
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Shop;
