import React from 'react';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Header from './layouts/Header';
import Footer from './layouts/Footer';

const SubCategories = () => {

const [subcategories, setCats] = useState([]);
const [isLoading, setIsLoading] = useState(true);

const fetchData = () =>{
return fetch("https://myduka.codekali.com/api/subcategories")
// return fetch("https://myduka.codekali.com/api/getcategories")
.then((response) => response.json())
.then((data) => setCats(data['subcategories']))
 .finally(() => {
   // console.log(data);
  setIsLoading(false);
 });
}
 
useEffect(() => {
setIsLoading(true);
fetchData();
// setIsLoading(false);
},[])
 
const subcategoriesCount = subcategories.length;

return(
   <>
      <Header/>
      <div className="container">
         <div className="py-3"></div>
         <h5 style={{ marginBottom: "1.5rem" }}> {subcategoriesCount<1 ? 'Loading...' : 'Manage Sub Categories('+subcategoriesCount+')'} </h5>
         <div className="user-container">
            <div className="row">
               { !isLoading ? (subcategories&&subcategories.length>0 && subcategories.map(subcategoriesObj => (
               <div key={subcategoriesObj.id} className="col-md-4 mb-3">
                  <div className="card soko-round shadow-sm h-100">
                     <div className="card-body">
                        <img loading="lazy" className="img-fluid cat-thumbnail mb-3" src={subcategoriesObj.image ? `https://myduka.codekali.com/subcategory/image/${subcategoriesObj.image}` : `https://myduka.codekali.com/images/avatar.png`} 
                        alt="{subcategoriesObj.name}" />
                        <h6>{subcategoriesObj.name}</h6>
                     </div>
                     <div className="card-footer text-center">
                        <Link to={`/subcategory/edit/${subcategoriesObj.id}`} className='btn btn-outline-primary me-2'>
                        <FontAwesomeIcon icon={faEdit} />
                        Edit
                        </Link>
                        <Link className="btn btn-outline-warning" to={'/create-subcategory'}>
                        <FontAwesomeIcon icon={faPlus} />
                        Add New</Link>
                     </div>
                  </div>
               </div>
               ))) :  Array(50)
               .fill()
               .map((item, index) => (
               <div className="col-md-4 mb-3" key={index}>
                  <div className="card soko-card soko-round shadow-sm">
                     <div className="card-body">
                        <h4 className="card-title">
                           <Skeleton circle={true} height={50} width={50} />
                           <Skeleton height={25} width={`80%`} />
                        </h4>
                        <p className="card-channel mb-0">
                           <Skeleton width={`60%`} />
                        </p>
                        <p className="card-channel">
                           <Skeleton width={`60%`} />
                        </p>
                        <div className="card-metrics">
                           <Skeleton width={`90%`} />
                        </div>
                     </div>
                  </div>
               </div>
               )) 
               }
            </div>
         </div>
      </div>
      <Footer/>
   </>

)
}

export default SubCategories
