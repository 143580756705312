import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import Select from "react-select";

export default function CreateSubCategory() {
  const navigate = useNavigate();

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [validationError, setValidationError] = useState({});
  const [category_id, setCategory] = useState("");

  const [cats, setCats] = useState([]);

  const catresults = [];

  cats.forEach((mycat, index) => {
    catresults.push({ value: mycat.id, label: mycat.name });
  });


  useEffect(() => {
    fetchData();
  }, []);

  const catOptions = [];
  cats.forEach((mycat, index) => {
    catOptions.push({ value: mycat.id, label: mycat.name });
  });

  const fetchData = () => {
    return (
      fetch("https://myduka.codekali.com/api/categories")
        // return fetch("https://myduka.codekali.com/api/categories")
        .then((response) => response.json())
        .then((data) => setCats(data["categories"]))
    );
  };

  const createSubCategory = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("name", name);
    formData.append("description", description);
    formData.append("category_id", category_id);
    formData.append("image", image);

    await axios
      .post(`https://myduka.codekali.com/api/subcategories`, formData)
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        navigate("/subcategories");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setValidationError(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      });
  };

  const handleChangee = (event, value) => {
    if (!event) {
      setCategory("");
    } else {
      setCategory(event.value);
    }
  };

  const changeHandler = (event) => {
    if (!event) {
      setImage("");
    } else {
      setImage(event.target.files[0]);
    }
  };

  return (
    <>
      <Header />
      <div className="container">
        <div className="py-3"></div>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card shadow-sm soko-rounded">
              <div className="card-body">
                <h4 className="card-title">Create SubCategory</h4>
                <hr />
                <div className="form-wrapper">
                  {Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">
                            {Object.entries(validationError).map(
                              ([key, value]) => (
                                <li key={key}>{value}</li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                  <Form onSubmit={createSubCategory}>
                    <Row className="mb-3">
                      <Col>
                        <Form.Group controlId="Name">
                          <Form.Label>SubCategory Name</Form.Label>
                          <Form.Control
                            type="text"
                            value={name}
                            onChange={(event) => {
                              setName(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Form.Label>SubCategory Category </Form.Label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={catOptions[0]}
                        isClearable={isClearable}
                        name="category_id"
                        isSearchable={isSearchable}
                        options={catOptions}
                        onChange={handleChangee}
                      />
                    </Row>

                    <Row className="my-3">
                      <Col>
                        <Form.Group controlId="Description">
                          <Form.Label>SubCategory Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={description}
                            onChange={(event) => {
                              setDescription(event.target.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="Image" className="mb-3">
                          <Form.Label>SubCategory Image</Form.Label>
                          <Form.Control
                            accept="image/*"
                            type="file"
                            onChange={changeHandler}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      variant="primary"
                      className="mt-2"
                      size="lg"
                      block="block"
                      type="submit"
                    >
                      Save
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
