import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStore } from "@fortawesome/free-solid-svg-icons";
// const fallbackSrc = process.env.PUBLIC_URL + "media/default.png";
const fallbackSrc = "https://codekali.com/images/avatar.png";

const UserCard = ({ usersObj }) => {
  return (
    <div key={usersObj.id} className="col-md-3 mb-3">
      <div className="card soko-round shadow-lg h-100">
        <div className="card-body">
          <img onError={(e)=>{e.target.onError = null; e.target.src = fallbackSrc}} loading="lazy" className="soko-round" width="50" height="50" 
          src={usersObj.image ? `https://myduka.codekali.com/images/${usersObj.image}` : `https://myduka.codekali.com/images/avatar.png`} 
          alt={usersObj.name}  />
          <h4><small>{usersObj.name}</small></h4>
          <small>Email: {usersObj.email}</small>
          <p>Username: {usersObj.slug}</p>
          <p>Phone: {usersObj.phone ?   usersObj.phone  : `0700422699`} </p>
          <div className="text-center mb-3">
          <Link className="btn btn-primary soko-round w-100" to={`/vendor/${usersObj.id}`}><FontAwesomeIcon icon={faStore} /> Visit Store</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
