import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useParams } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import Header from './layouts/Header';
import Footer from './layouts/Footer';

export default function Category() {

  const { slug } = useParams();

  const [name, setName] = useState("");


 
const [products, setProducts] = useState([]);
const [cats, setCats] = useState([]);
const [subcats, setSubCats] = useState([]);
const [isLoading, setIsLoading] = useState(true);

  useEffect(()=>{
     const fetchCategory = async () => {
    await axios.get(`https://myduka.codekali.com/api/category/${slug}`).then(({data})=>{
      const { name } = data.category
      setName(name)
      setCats(data.categories)
      setSubCats(data.subcategories)
    }).catch(({response:{data}})=>{
      Swal.fire({
        text:data.message,
        icon:"error"
      })
    })
  } 

  const fetchData = () =>{
return fetch(`https://myduka.codekali.com/api/category/${slug}`)
.then((response) => response.json())
.then((data) => setProducts(data['category']['products']))
 .finally(() => {
  setIsLoading(false);
 });
} 
    fetchCategory();
    fetchData();
  },[slug])


// console.log(subcats);


   function capFirst(str) {

   let dots

   if(str.length > 50){
      dots = '...';
   }else{
      dots = '';

   }

     return (str[0].toUpperCase() + (str.slice(1)).toLowerCase()).slice(0, 50)+dots;
     
 }
  return (
    <>
      <Header/>
    <div className="container-fluid">
       <div className="py-2"></div>

       <div className="row">

       <div className="col-md-3 mobile-hide">
       <div className="card shadow-sm">
       <div className="card-body">
       <h6 className="mb-3 soko-bold">Shop By Category</h6>
       <hr/>
        <ul className="p-0">
                {!isLoading
                  ? cats &&
                    cats.length > 0 &&
                    cats.map((catsObj) => (
                        <Link key={catsObj.id} to={`/category/${catsObj.slug}`}>
                      <li className="mb-3">
                        {catsObj.name}{" "}
                      </li>
                         </Link>
                    ))
                  : Array(6)
                      .fill()
                      .map((item, index) => (
                        <li className="mb-3" key={index}>
                              <Skeleton height={30} width={`100%`} />
                        </li>
                      ))}
              </ul>

       </div>
       </div>
       </div>

       <div className="col-md-9">


        <div className="row justify-content-center">
          {!isLoading
            ? subcats &&
              subcats.length > 0 &&
              subcats.map((subcatsObj) => (
                <div key={subcatsObj.id} className="col-md-2 col-6 mb-3">
                  <Link
                    className="text-site"
                    to={`/subcategory/${subcatsObj.slug}`}
                  >
                    <div className="card soko-cat-card shadow-sm h-100">
                      <div className="card-img-top">
                        <img
                          loading="lazy"
                          className="img-fluid soko-cat-img"
                          src={
                            subcatsObj.image
                              ? `https://myduka.codekali.com/subcategory/image/${subcatsObj.image}`
                              : `https://myduka.codekali.com/app/images/avatar.png`
                          }
                          alt="{subcatsObj.name}"
                        />
                      </div>
                      <div className="card-img-overlay text-white d-flex justify-content-center align-items-center">
                        <h6 className="text-white soko-bold">
                          {subcatsObj.name}
                        </h6>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            : Array(6)
                .fill()
                .map((item, index) => (
                  <div className="col-md-2 col-6 mb-3" key={index}>
                    <div className="card shadow-sm">
                      <div className="card-body">
                        <div style={{ height: "80px" }}>
                          <Skeleton height={80} width={`100%`} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          <Skeleton width={`60%`} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
        </div>

   <h5 className="card-title mb-3 soko-bold"> {isLoading ? 'Loading...' : ''+name+''} </h5>

         <div className="product-container">
            <div className="row g-1">
               { !isLoading ? (products&&products.length>0 && products.map(productsObj => (
               <div key={productsObj.id} className="col-md-3 col-6 mb-3">
                  <div className="card soko-rounded shadow-sm h-100">
                     <div className="card-body">
                      <Link to={`/product/${productsObj.slug}`}>
                        <img loading="lazy" className="img-fluid mb-3 product-img w-100" 
                        src={productsObj.image ? `https://myduka.codekali.com/product/image/${productsObj.image}` : `https://myduka.codekali.com/images/avatar.png`} 
                        alt="{productsObj.name}" />
                        <h6 className="mb-2 soko-mobile-font">{capFirst(productsObj.name)}</h6>
                         </Link>
                        <h6 className="soko-bold mb-3">KSh {productsObj.price}</h6>
                        
                     </div>
                  </div>
               </div>
               ))) : 


                 Array(50)
               .fill()
               .map((item, index) => (
               <div className="col-md-3 mb-3" key={index}>
                  <div className="card soko-rounded shadow-sm">
                     <div className="card-body">
                        <div style={{ height: "200px", marginBottom: ".5rem" }}>
                           <Skeleton height={200} width={`100%`} />
                        </div>
                           <h4 className="card-title">
                           <Skeleton height={25} width={`80%`} />
                        </h4>
                        <p className="card-channel mb-0">
                           <Skeleton width={`60%`} />
                        </p>
                        <div className="card-metrics">
                           <Skeleton width={`90%`} />
                        </div>
                     </div>
                  </div>
               </div>
               )) 
               }
            </div>
         </div>
       </div>

       </div>
 
      
             
    </div>
      <Footer/>
    </>
  )
}