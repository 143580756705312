import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import Header from './layouts/Header';
import Footer from './layouts/Footer';

const Dashboard = () => {

return(
<>
<Header/>
<div className="container-fluid">
     <div className="py-3"></div>
     <div className="row mb-3">

           <div className="col-md-3 mb-3 text-center">
         <Link className="text-site" to={'/shop'}>
            <div className="card soko-rounded shadow-sm">
               <div className="card-body">
                  <small><FontAwesomeIcon icon={faShoppingBag} /> Shop Now</small>
               </div>
            </div>
         </Link>
      </div>
      
      <div className="col-md-3 mb-3 text-center">
         <Link className="text-site" to={'/create-subcategory'}>
            <div className="card soko-rounded shadow-sm">
               <div className="card-body">
                  <small><FontAwesomeIcon icon={faPlusCircle} /> Create SubCategory</small>
               </div>
            </div>
         </Link>
      </div>
      <div className="col-md-3 mb-3 text-center">
         <Link className="text-site" to={'/create-category'}>
            <div className="card soko-rounded shadow-sm">
               <div className="card-body">
                  <small><FontAwesomeIcon icon={faPlusCircle} /> Create Category</small>
               </div>
            </div>
         </Link>
      </div>
      <div className="col-md-3 mb-3 text-center">
         <Link className="text-site" to={'/sell'}>
            <div className="card soko-rounded shadow-sm">
               <div className="card-body">
                  <small><FontAwesomeIcon icon={faPlus} /> Create Product</small>
               </div>
            </div>
         </Link>
      </div>
      <div className="col-md-3 mb-3 text-center">
         <Link className="text-site" to={'/products'}>
            <div className="card soko-rounded shadow-sm">
               <div className="card-body">
                  <small><FontAwesomeIcon icon={faCartShopping} /> All Products</small>
               </div>
            </div>
         </Link>
      </div>
      <div className="col-md-3 mb-3 text-center">
         <Link className="text-site" to={'/shops'}>
            <div className="card soko-rounded shadow-sm">
               <div className="card-body">
                  <small><FontAwesomeIcon icon={faUsers} /> All Vendors</small>
               </div>
            </div>
         </Link>
      </div>
            <div className="col-md-3 mb-3 text-center">
         <Link className="text-site" to={'/categories'}>
            <div className="card soko-rounded shadow-sm">
               <div className="card-body">
                  <small><FontAwesomeIcon icon={faList} /> All Categories</small>
               </div>
            </div>
         </Link>
      </div>

          <div className="col-md-3 mb-3 text-center">
         <Link className="text-site" to={'/subcategories'}>
            <div className="card soko-rounded shadow-sm">
               <div className="card-body">
                  <small><FontAwesomeIcon icon={faList} /> All SubCategories</small>
               </div>
            </div>
         </Link>
      </div>
   </div> 
</div>
<Footer/>
</>
)

}

export default Dashboard
