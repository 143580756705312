import React, { useContext } from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import { useState } from "react";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import SiteLogo from "../../images/logo2.png";
import Dropdown from "react-bootstrap/Dropdown";
import placeholder from "../../images/lazyloader.png";
import { CartContext } from "../../context/cart.jsx";
import Cart from "../Cart";

const Header = () => {
  const navigate = useNavigate();

  let user = JSON.parse(localStorage.getItem("user-info"));

  // console.log(user['user']['name'])

  function logOut() {
    localStorage.clear();
    navigate("/login");
  }

  function capFirst(str) {
    let dots;

    if (str.length > 50) {
      dots = "...";
    } else {
      dots = "";
    }

    return (
      (str[0].toUpperCase() + str.slice(1).toLowerCase()).slice(0, 50) + dots
    );
  }

  const [isSearching, setIsSearching] = useState(false);

  const searchCSS = "soko-search-results";
  const searchHidden = searchCSS + " d-none";

  const [searchproducts, setSearchData] = useState([]);

  async function sokoSearch(key) {
    if (key === "") {
      setIsSearching(false);
      // alert('empty');
    } else {
      setIsSearching(true);
    }

    let result = await fetch("https://myduka.codekali.com/api/search/" + key);

    result = await result.json();

    if (result.length > 0) {
      setSearchData(result);
    } else {
      setIsSearching(false);
      // alert('empty');
    }
    // console.warn(result);

    // console.log(searchproducts);
  }

  // cart
  const [show, setShow] = useState(false);

  const [showModal, setshowModal] = useState(false);
  const { cartItems, addToCart, removeFromCart, getCartTotal } =
    useContext(CartContext);
  const handleShow = () => setShow(true);

  const toggle = () => {
    setshowModal(!showModal);
  };

  return (
    <>
      <div className="col-md-6 px-2 py-2 desktop-hide">
        <div className="position-relative soko-app-search-wrapper">
          <div className="form-group">
            <input
              onChange={(e) => sokoSearch(e.target.value)}
              className="form-control shadow-none"
              type="search"
              placeholder="Search 1,000,000 products..."
            />
          </div>
          <div className={isSearching ? searchCSS : searchHidden}>
            <ul className="list-group">
              {searchproducts.map((productsObj) => (
                <Link
                  to={`/product/${productsObj.slug}`}
                  key={productsObj.id}
                  className="list-group-item d-flex justify-content-between"
                >
                  <div className="">
                    <ProgressiveImage
                      src={
                        productsObj.image
                          ? `https://myduka.codekali.com/product/image/${productsObj.image}`
                          : `https://myduka.codekali.com/images/avatar.png`
                      }
                      placeholder={placeholder}
                    >
                      {(src, loading) => (
                        <img
                          className={`image${
                            loading
                              ? " loading img-fluid search-product-img"
                              : " loaded img-fluid search-product-img"
                          }`}
                          src={src}
                          alt={productsObj.name}
                        />
                      )}
                    </ProgressiveImage>
                  </div>

                  <div className="text-end">
                    <p className="soko-bold">{capFirst(productsObj.name)}</p>

                    <p>KES {productsObj.price}</p>
                  </div>
                </Link>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <header className="fixed-top px-0 float-juu-kabisa shadow-sm">
        <div className="text-bg-info border-bottom">
          <div className="container-fluid">
            <div className="d-flex flex-wrap align-items-center justify-content-between justify-content-lg-between">
              <Link
                to={"/"}
                className="d-flex align-items-center my-lg-0 text-white text-decoration-none"
              >
                <img
                  src={SiteLogo}
                  alt="Soko Header Logo"
                  className="soko-header-logo"
                />
              </Link>

              <div className="soko-app-search col-md-6 mobile-hide">
                <div className="position-relative soko-app-search-wrapper">
                  <div className="form-group">
                    <input
                      onChange={(e) => sokoSearch(e.target.value)}
                      className="form-control shadow-none"
                      type="search"
                      placeholder="Search 1,000,000 products..."
                    />
                  </div>
                  <div className={isSearching ? searchCSS : searchHidden}>
                    <ul className="list-group">
                      {searchproducts.map((productsObj) => (
                        <Link
                          to={`/product/${productsObj.slug}`}
                          key={productsObj.id}
                          className="list-group-item d-flex justify-content-between"
                        >
                          <div className="">
                            <ProgressiveImage
                              src={
                                productsObj.image
                                  ? `https://myduka.codekali.com/product/image/${productsObj.image}`
                                  : `https://myduka.codekali.com/images/avatar.png`
                              }
                              placeholder={placeholder}
                            >
                              {(src, loading) => (
                                <img
                                  className={`image${
                                    loading
                                      ? " loading img-fluid search-product-img"
                                      : " loaded img-fluid search-product-img"
                                  }`}
                                  src={src}
                                  alt={productsObj.name}
                                />
                              )}
                            </ProgressiveImage>
                          </div>

                          <div className="text-end">
                            <p className="soko-bold">
                              {capFirst(productsObj.name)}
                            </p>

                            <p>KES {productsObj.price}</p>
                          </div>
                        </Link>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <ul className="nav soko-app-header col-lg-auto py-3 me-lg-3 justify-content-center my-md-0 text-small">
                <li>
                  <Link to={"/"} className="nav-link d-none">
                    <img
                      src={SiteLogo}
                      alt="Soko Nav Logo"
                      className="soko-nav-logo"
                    />
                  </Link>
                </li>

                <li></li>

                {localStorage.getItem("user-info") ? (
                  <>
                    <li className="me-3">
                      <Dropdown className="text-end">
                        <Dropdown.Toggle
                          variant="transparent"
                          className="text-white"
                          id="dropdown-basic"
                        >
                          <FontAwesomeIcon icon={faUserCircle} /> Account
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to={"/dashboard"}
                            className="std-font text-dark mb-2"
                          >
                            Dashboard
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Link}
                            to={"/"}
                            className="std-font text-dark mb-2"
                          >
                            Settings
                          </Dropdown.Item>

                          {localStorage.getItem("user-info") ? (
                            <>
                              <Dropdown.Item
                                className="std-font mb-2"
                                title={
                                  user && user.user.name
                                    ? user.user.name
                                    : "Soko User"
                                }
                                onClick={logOut}
                              >
                                <FontAwesomeIcon icon={faSignOut} /> Sign out
                              </Dropdown.Item>
                            </>
                          ) : (
                            <></>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>

                  </>
                ) : (
                  <>
                    <li>
                      <Dropdown className="text-end">
                        <Dropdown.Toggle
                          variant="transparent"
                          className="text-white soko-mobile-font"
                          id="dropdown-auth"
                        >
                          <FontAwesomeIcon icon={faUserCircle} /> Account
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to={"/register"}
                            className="std-font text-dark mb-2"
                          >
                            Register
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Link}
                            to={"/login"}
                            className="std-font text-dark mb-2"
                          >
                            Login
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>

                    <li>
                      <Dropdown className="text-end">
                        <Dropdown.Toggle
                          variant="transparent"
                          className="text-white soko-mobile-font"
                          id="dropdown-help"
                        >
                          <FontAwesomeIcon icon={faUserCircle} /> Help
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            as={Link}
                            to={"/"}
                            className="std-font text-dark mb-2"
                          >
                            Contact
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Link}
                            to={"/"}
                            className="std-font text-dark mb-2"
                          >
                            TOS
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
 
                  </>
                )}


                     <li>
                      <span className="text-white soko-mobile-font">
                        {" "}
                         {getCartTotal()}
                      </span>{" "}
                      {!showModal && (
                        <button
                          className="btn btn-outline-light soko-mobile-font position-relative"
                          onClick={toggle}
                        >
                          {" "}
                          Cart
                          <span className="position-absolute top-0 start-100 soko-mobile-font translate-middle badge rounded-pill bg-danger">
                            {cartItems.length}
                            <span className="visually-hidden">
                              unread messages
                            </span>
                          </span>
                        </button>
                      )}
                    </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <Cart showModal={showModal} toggle={toggle} />
    </>
  );
};

export default Header;
