import React, { useEffect,useState } from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Header from './layouts/Header';
import Footer from './layouts/Footer';

import UserCard from "./UserCard";

const Users = () => {

const [user,setUser] = useState([]);
const [isLoading, setIsLoading] = useState(true);
 
// fetch users data from fetch method || variable users

const fetchData = () =>{
return fetch("https://myduka.codekali.com/api/users")
// return fetch("https://myduka.codekali.com/api/users")
.then((response) => response.json())
.then((data) => setUser(data['users']))
 .finally(() => {
  setIsLoading(false);
 });
}


useEffect(() => {

setIsLoading(true);
fetchData();

},[])

	return(
   <>
      <Header/>
      <div className="container-fluid">
         <div className="py-3"></div>
            <div className="row">
               { !isLoading ? (user&&user.length>0 && user.map(usersObj => (
               <UserCard usersObj={usersObj} key={usersObj.id} />
               ))) :  Array(50)
               .fill()
               .map((item, index) => (
               <div className="col-md-3 mb-3" key={index}>
                  <div className="card soko-card soko-round shadow-lg">
                     <div className="card-body">
                        <h4 className="card-title">
                           <Skeleton circle={true} height={50} width={50} />
                           <Skeleton height={25} width={`80%`} />
                        </h4>
                        <p className="card-channel mb-0">
                           <Skeleton width={`60%`} />
                        </p>
                        <p className="card-channel">
                           <Skeleton width={`60%`} />
                        </p>
                        <div className="card-metrics">
                           <Skeleton width={`90%`} />
                        </div>
                     </div>
                  </div>
               </div>
               )) 
               }
            </div>
      </div>
      <Footer/>
   </>
	)
}

export default Users