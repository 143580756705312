import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStore } from "@fortawesome/free-solid-svg-icons";
const fallbackSrc = "https://myduka.codekali.com/images/myshop.jpg";

const Shops = () => {
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    return (
      fetch("https://myduka.codekali.com/api/users")
        .then((response) => response.json())
        .then((data) => setUser(data["users"]))
        .finally(() => {
          setIsLoading(false);
        })
    );
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, []);

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="py-3"></div>
        <h4 className="soko-bold">Soko Vendors</h4>
        <div className="row">
          {!isLoading
            ? user &&
              user.length > 0 &&
              user.map((usersObj) => (
                <div key={usersObj.id} className="col-md-3 mb-3">
                  <div className="card soko-round shadow-sm h-100">
                    <div className="card-body">
                      <img
                        onError={(e) => {
                          e.target.onError = null;
                          e.target.src = fallbackSrc;
                        }}
                        loading="lazy"
                        className="soko-round"
                        width="50"
                        height="50"
                        src={
                          usersObj.image
                            ? `https://myduka.codekali.com/images/${usersObj.image}`
                            : `https://myduka.codekali.com/images/myshop.jpg`
                        }
                        alt={usersObj.name}
                      />
                      <h4>
                        <small>{usersObj.name}</small>
                      </h4>
                      <small>Email: {usersObj.email}</small>
                      <p>Username: {usersObj.slug}</p>
                      <p>
                        Phone: {usersObj.phone ? usersObj.phone : `0700422699`}{" "}
                      </p>
                      <div className="text-center mb-3">
                        <Link
                          className="btn btn-primary soko-round w-100"
                          to={`/shop/${usersObj.slug}`}
                        > 
                          <FontAwesomeIcon icon={faStore} /> Visit Shop
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : Array(50)
                .fill()
                .map((item, index) => (
                  <div className="col-md-3 mb-3" key={index}>
                    <div className="card soko-card soko-round shadow-sm">
                      <div className="card-body">
                        <h4 className="card-title">
                          <Skeleton circle={true} height={50} width={50} />
                          <Skeleton height={25} width={`80%`} />
                        </h4>
                        <p className="card-channel mb-0">
                          <Skeleton width={`60%`} />
                        </p>
                        <p className="card-channel">
                          <Skeleton width={`60%`} />
                        </p>
                        <div className="card-metrics">
                          <Skeleton width={`90%`} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Shops;
